@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&family=IBM+Plex+Sans:wght@500;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/*Base*/
@layer base {
  body {
    @apply font-body text-gray;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-title;
  }
}

/* Components */
@layer components {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    @apply bg-gray-light bg-opacity-40;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    @apply bg-gray-light bg-opacity-40;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    @apply bg-secondary;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply text-sm text-black-100 opacity-80 font-body font-medium rounded-md outline-none h-[50px] border-gray-light focus:border-secondary focus:outline-none focus:ring-0 focus:bg-white focus:shadow-none;
    caret-color: #45c1c5;
  }

  input::placeholder {
    @apply text-[13px] placeholder-black-100 placeholder-opacity-70 font-body font-normal;
  }

  [type='radio'] {
    @apply w-[18px] h-[18px] border-none text-gray bg-radio-off bg-no-repeat !shadow-none ring-0 ring-offset-0 outline-none rounded-full focus:!shadow-none disabled:bg-radio-off-disabled cursor-pointer;
  }

  [type='radio']:checked {
    @apply text-gray bg-radio disabled:bg-radio-disabled;
  }

  [type='checkbox'] {
    @apply w-[18px] h-[18px] bg-checkbox-off bg-no-repeat rounded border-0 disabled:bg-checkbox-off-disabled cursor-pointer;
  }

  [type='checkbox']:checked {
    @apply text-gray bg-checkbox disabled:bg-checkbox-disabled;
  }

  [type='radio'],
  [type='radio']:focus,
  [type='checkbox'],
  [type='checkbox']:focus {
    @apply shadow-transparent !important;
  }

  [type='radio'] ~ label,
  [type='checkbox'] ~ label {
    @apply cursor-pointer;
  }

  html {
    scroll-behavior: smooth;
  }

  input[type='range']::-webkit-slider-thumb {
    transform: translateZ(0);
    border: 3px solid #45c1c5;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 2px;
    position: relative;
    z-index: 1;
    pointer-events: all;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .hide-stepper::-webkit-outer-spin-button,
  .hide-stepper::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='date'],
  [type='datetime-local'],
  [type='month'] {
    @apply pr-3 !important;
  }

  /**/
  select {
    background-image: url('/icons/caret.svg');
    background-size: inherit;
    background-position-x: 95%;
  }

  select:invalid {
    @apply opacity-70 text-gray;
  }

  [type='button'],
  [type='submit'] {
    @apply focus:ring-0 focus:outline-none;
  }

  .btn {
    @apply inline-flex items-center justify-center w-auto h-10 px-4 py-1 text-sm font-medium rounded-lg sm:h-12 sm:px-5 sm:py-2 disabled:opacity-60 disabled:pointer-events-none disabled:cursor-auto focus:outline-none focus:ring-0;
  }

  .btn-input {
    @apply p-1 font-medium border rounded-md shadow-sm outline-none border-gray-light;
  }

  .btn-primary {
    @apply text-white border border-transparent bg-primary;
  }

  .btn-secondary {
    @apply font-bold tracking-tighter bg-white border rounded-md font-title border-secondary text-secondary hover:bg-secondary hover:text-white hover:border-transparent;
  }

  .btn-secondary svg path {
    fill: #45c1c5;
  }

  .btn-secondary:hover svg path {
    fill: #fff;
  }

  .btn-secondary-fill {
    @apply font-bold tracking-tighter text-white border rounded-md bg-secondary font-title border-secondary hover:bg-white hover:text-secondary hover:border-secondary;
  }

  .btn-secondary-fill svg path {
    fill: #fff;
  }

  .btn-secondary-fill:hover svg path {
    fill: #45c1c5;
  }

  .btn-group {
    @apply flex-1 p-1 text-sm font-bold uppercase h-[50px] text-secondary text-opacity-80 font-title;
  }

  .label {
    @apply block mt-5 mb-1.5 font-medium uppercase font-title text-gray opacity-70 text-xs11;
  }

  .el-input-error {
    @apply absolute right-0 mt-1 text-xs text-red;
  }

  .el-input-otp {
    @apply w-full text-2xl;
  }

  .el-input-happ-text {
    @apply absolute right-0 block mt-0 font-bold leading-7 tracking-normal capitalize text-xs11 opacity-70 text-gray;
  }

  .el-user-menu:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 19px;
    top: -5px;
    background: #fff;
    transform: rotateZ(45deg);
  }

  .testimonial-slider .slick-prev::before,
  .testimonial-slider .slick-next::before {
    content: '';
  }

  .testimonial-slider .slick-arrow {
    width: 40px;
    height: 40px;
    box-shadow: 4px -4px 20px 0 rgba(38, 53, 66, 0.2);
    top: inherit;
    left: inherit;
    bottom: -75px;
    border-radius: 15px;
  }

  .testimonial-slider .slick-prev {
    right: 120px;
  }

  .testimonial-slider .slick-next {
    right: 64px;
  }

  .news-slider .slick-list {
    padding-left: 0px !important;
  }

  .news-slider .slick-prev::before,
  .news-slider .slick-next::before {
    content: '';
  }

  .news-slider .slick-arrow {
    width: 40px;
    height: 40px;
    box-shadow: 4px -4px 20px 0 rgba(38, 53, 66, 0.2);
    top: inherit;
    left: inherit;
    bottom: -75px;
    border-radius: 15px;
  }

  .news-slider .slick-prev {
    right: 120px;
  }

  .news-slider .slick-next {
    right: 64px;
  }

  @media (max-width: 767px) {
    .news-slider .slick-prev {
      right: 100px;
    }

    .news-slider .slick-next {
      right: 44px;
    }
  }

  .bank-card {
    @apply relative z-10 items-center px-4 bg-white shadow-app-card rounded-xl;
    perspective: 1000;
  }

  .bank-card-inner {
    transition: 0.5s;
    transform-style: preserve-3d;
    position: relative;
    height: 100%;
  }

  .bank-card:hover .bank-card-inner {
    transform: rotateY(180deg);
  }

  .small-bank-card {
    @apply h-[40px] min-w-[60px] lg:h-[70px] p-0;
  }

  .large-bank-card {
    @apply px-4 lg:px-4 bg-white rounded-xl min-w-[132px] md:min-w-[215px] h-[40px] lg:min-w-[215px] lg:h-[70px];
  }

  .empty-bank-card-bottom {
    @apply rounded-xl min-w-[30px] h-[30px] mt-[5px] lg:min-w-[60px] lg:h-[60px] lg:mt-[10px];
  }

  .empty-bank-card-top {
    @apply rounded-xl min-w-[30px] h-[30px] mb-[5px] lg:min-w-[60px] lg:h-[60px] lg:mb-[10px];
  }

  .front-img,
  .back-img {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .front-img {
    z-index: 1;
    transform: rotateY(0deg);
  }
  .back-img {
    z-index: 2; /* back side, placed above front */
    transform: rotateY(180deg);
  }

  .illustration svg {
    @apply max-w-[100%];
  }

  .tab-with-bg {
    @apply flex items-center flex-shrink-0 px-8 py-3 space-x-2 text-sm font-bold text-white uppercase rounded-full cursor-pointer lg:px-12 leading-0 bg-none focus:outline-none font-title;
  }

  .tab-with-bg-active {
    @apply flex items-center flex-shrink-0 px-8 py-3 space-x-2 text-sm font-bold uppercase bg-white rounded-full cursor-pointer lg:px-12 leading-0 text-primary focus:outline-none font-title;
  }

  .tab-with-bg:hover stop {
    stop-color: '#F00';
  }
  .works-grid li:first-child svg {
    @apply hidden;
  }

  /* Typography */
  .app-heading-big {
    @apply text-3xl font-bold font-title lg:text-5xl;
  }

  .app-heading-main {
    @apply font-bold font-title text-3xl md:text-[35px] md:leading-10;
  }
  .app-heading-primary {
    @apply font-bold font-title text-[25px] leading-[30px];
  }
  .app-heading-secondary {
    @apply text-xl font-semibold font-title;
  }

  .app-text-main-link {
    @apply text-base font-bold font-title;
  }
  .app-text-sub-main-link {
    @apply text-base font-medium font-title;
  }
  .app-text-link {
    @apply text-sm font-bold font-title;
  }
  .upload-csv-link svg {
    @apply hidden transition ease-in-out;
  }
  .upload-csv-link:hover svg {
    @apply block;
  }
  .app-text-input-primary {
    @apply font-title text-sm font-medium leading-[18px];
  }
  .app-text-input-secondary {
    @apply font-title text-[11px] font-medium;
  }
  .app-text-dm-large {
    @apply font-body text-[22px] font-bold;
  }
  .app-text-dm-regular {
    @apply font-body text-base font-medium leading-[22px];
  }
  .app-text-dm-small {
    @apply text-sm font-medium font-body;
  }
  .app-text-dm-xs {
    @apply font-body text-[13px];
  }
  .app-text-dm-xxs {
    @apply font-body text-[10px] font-medium;
  }
  .app-text-sidenav {
    @apply font-body text-[15px] font-medium;
  }
  .app-text-table-cell {
    @apply px-4 py-4 text-sm font-medium text-left font-body text-gray text-opacity-60 whitespace-nowrap;
  }
  .app-form-wrapper {
    @apply block mb-1 uppercase;
  }
  .app-form-label {
    @apply font-title text-[11px] text-gray opacity-60 font-medium block uppercase;
  }
  .app-form-label-large {
    @apply font-title text-[14px] text-gray opacity-60 font-medium block uppercase;
  }
  .app-input-error {
    @apply text-red font-title text-[11px] font-medium uppercase m-0;
  }
  .app-input-error-large {
    @apply text-red font-title text-[14px] font-medium uppercase m-0;
  }
  .app-page-title {
    @apply font-bold font-title text-[25px] md:text-[35px] leading-8 md:leading-10 text-primary;
  }
  .app-page-sub-title {
    @apply mt-1 text-sm text-gray-400 font-body text-opacity-80;
  }
  /* Buttons */
  .app-btn {
    @apply inline-flex items-center justify-center h-[45px] px-[15px] md:px-[25px] text-sm font-medium rounded-lg hover:shadow-btn-shadow-app disabled:opacity-60 disabled:pointer-events-none disabled:cursor-auto focus:outline-none focus:ring-0 text-white border border-transparent space-x-[5px] w-[230px];
  }

  .app-btn-default-blank-outline-icon {
    @apply text-xs flex flex-col font-medium h-[67px] bg-white border space-x-[5px] w-[310px] border-green text-primary rounded-xl text-[16px];
  }
  .app-btn-default-choice-btn {
    @apply text-xs flex flex-col justify-center  font-medium h-[67px] bg-white shadow-card-shadow   space-x-[5px] w-[310px]  text-primary rounded-xl text-[16px];
  }

  .app-btn-default-choice-btn {
    @apply text-xs flex flex-col justify-center  font-medium h-[67px]  bg-white shadow-card-shadow   space-x-[5px] w-[310px]  text-primary rounded-xl text-[16px];
  }
  .app-btn-diabled-choice-btn {
    @apply text-xs flex flex-col justify-center  font-medium h-[67px] opacity-50 bg-white shadow-card-shadow   space-x-[5px] w-[310px]  text-primary rounded-xl text-[16px];
  }
  .app-btn-profile-btn {
    @apply text-xs flex flex-col justify-center  font-medium h-[36px] w-auto min-w-[160px] bg-light-400 shadow-card-shadow   space-x-[5px]   text-primary rounded-[120px] text-[14px];
  }
  .app-btn-primary {
    @apply transition-transform ease-out transform bg-primary hover:-translate-y-1;
  }
  .app-btn-primary-outline {
    @apply text-xs font-medium bg-white border-primary text-primary hover:bg-primary hover:text-white rounded-xl;
  }

  .app-btn-secondary {
    @apply text-xs font-bold bg-white border-secondary text-secondary hover:bg-secondary hover:text-white;
  }
  .app-btn-secondary-small {
    @apply bg-white font-bold border-secondary text-secondary hover:bg-secondary hover:text-white h-[35px] px-[20px];
  }
  .app-btn-secondary-fill {
    @apply bg-secondary;
  }
  .app-with-icon {
    @apply px-0 w-[45px] leading-4 disabled:opacity-60;
  }
  /* Labels */
  .app-label {
    @apply inline-flex items-center justify-center w-auto h-[30px] px-[15px] text-xxs font-bold rounded-md sm:px-5 sm:py-2 border border-transparent space-x-2;
  }
  .app-label-small {
    @apply inline-flex items-center justify-center w-auto h-[25px] min-w-[100px] text-xxs font-bold rounded-md px-3 border border-transparent space-x-2;
  }
  .app-label-purple {
    @apply text-white bg-purple font-body;
  }
  .app-label-pink {
    @apply text-white bg-pink font-body;
  }
  .app-label-blue {
    @apply text-white bg-blue font-body;
  }
  .app-label-added {
    @apply uppercase text-status-100 bg-statusLight-100 font-title;
  }
  .app-label-processed {
    @apply uppercase text-status-200 bg-statusLight-200 font-title;
  }
  .app-label-rejected {
    @apply uppercase text-status-300 bg-statusLight-300 font-title;
  }
  .app-label-expired {
    @apply uppercase text-status-400 bg-statusLight-400 font-title;
  }
  .app-label-connected {
    @apply uppercase text-status-500 bg-statusLight-500 font-title;
  }
  /* Account Slider */
  .account-slider .slick-dots li button:before {
    content: '';
  }
  .account-slider .slick-dots li {
    @apply h-[2px];
  }
  .account-slider .slick-dots li button {
    @apply bg-secondary bg-opacity-20 w-[25px] h-[2px] p-0;
  }
  .account-slider .slick-dots li.slick-active button {
    @apply bg-opacity-100;
  }
  .account-slider .slick-dots {
    bottom: -30px;
  }
  .right-21 {
    right: -21px;
  }
  .right--1 {
    right: -1px;
  }
  /* Date Picker */
  .app-date .react-date-picker {
    @apply shadow-none;
  }
  .app-date .react-date-picker__wrapper {
    @apply pl-5 pr-9 h-[50px];
  }
  .app-date .react-date-picker__calendar-button {
    position: absolute;
    right: 20px;
    height: 100%;
  }
  /* Dashboard Chart */
  .circle {
    fill: none;
    stroke-width: 1.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  .percentage {
    font-size: 6px;
    font-weight: 400;
    text-anchor: middle;
  }
  /* Progress Bar */
  .progressbar-circle {
    display: inline-block;
    margin: auto;
  }
  .progressbar-circle-bg {
    fill: none;
    stroke: #d4f2f3;
    stroke-width: 1.8;
  }
  .progressbar-circle {
    fill: none;
    stroke-width: 1.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    stroke: #45c1c5;
  }
  /* MultiRange */
  .range-wrapper {
    @apply mt-4;
  }
  .range-slider {
    position: relative;
    width: 100%;
  }
  .range-slider__track,
  .range-slider__range,
  .range-slider__left-value,
  .range-slider__right-value {
    position: absolute;
  }
  .range-slider__track,
  .range-slider__range {
    @apply rounded-full;
    height: 3px;
  }
  .range-slider__track {
    @apply bg-secondary-300;
    width: 100%;
    z-index: 1;
  }
  .range-slider__range {
    @apply bg-secondary;
    z-index: 2;
  }
  .range-slider__left-value,
  .range-slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }
  .range-slider__left-value {
    left: 6px;
  }
  .range-slider__right-value {
    right: -4px;
  }
  /* Removing the default appearance */
  .range-thumb,
  .range-thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .range-thumb {
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }
  .range-thumb--left {
    z-index: 3;
  }
  .range-thumb--right {
    z-index: 4;
  }
}
.tooltip-content {
  display: none;
}
.tooltip:hover ~ .tooltip-content {
  display: block;
}
.card-with-image-text h3 {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.qr-reader > section {
  padding-top: 0 !important;
  height: 100% !important;
}
.pdf-preview {
  height: 180px;
  overflow: hidden;
}
.pdf-preview canvas {
  width: 100% !important;
  height: auto !important;
}
.pdf-preview img {
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
}
